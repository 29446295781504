<template>
   <div class="detail-set-wrapper">
        <div class="cover flex-box-column">
            <div class="hd">
                <van-nav-bar left-arrow :title="houseTitle"  @click-left="back" :right-text="rightText" @click-right="submitHouseData"></van-nav-bar>
            </div>
            <div class="bd">
                <div class="form-item">
                    <div class="form-item_title">报备设置</div>
                    <div class="form-item_content">
                        <van-field name="switch" :label-width="getVw(230)" label="是否开启分销报备" input-align="right" :disabled="disabled">
                            <template #input>
                                <van-switch 
                                :active-value="1"
                                :inactive-value="0"
                                v-model="houseData.isSellReport" 
                                :size="getVw(20)" 
                                :disabled="disabled"/>
                            </template>
                        </van-field>
                        <van-field 
                        v-model="houseData.commission.reportLeadTime"
                        label="报备提前时间" 
                        label-width="7em" 
                        placeholder="请输入报备提前时间" 
                        :disabled="disabled" type="number">
                        <template #extra>
                            <span class="color-999">分钟</span>
                        </template>
                        </van-field>  
                        <van-field name="switch" :label-width="getVw(230)" input-align="right" :disabled="disabled">
                            <template #input>
                                <van-switch 
                                :active-value="1"
                                :inactive-value="0"
                                v-model="houseData.isFullName" 
                                :size="getVw(20)" 
                                :disabled="disabled"/>
                            </template>
                            <template #label>
                                <p>全名报备</p>
                                <p class="color-999 ft-12">开启后分销报备的客户姓名不允许使用‘先生’、‘女士’等称呼</p>
                            </template>
                        </van-field>
                        <van-field name="switch" label="姓氏报备" :label-width="getVw(230)" input-align="right" :disabled="disabled">
                            <template #input>
                                <van-switch 
                                :active-value="1"
                                :inactive-value="0"
                                v-model="houseData.isNotFullName" 
                                :size="getVw(20)" 
                                :disabled="disabled" />
                            </template>
                            <template #label>
                                <p>姓氏报备</p>
                                <p class="color-999 ft-12">开启后分销报备的客户姓名不允许使用全名</p>
                            </template>
                        </van-field>   
                        <van-field name="switch" label="上传相关证件" :label-width="getVw(250)" input-align="right" :disabled="disabled">
                            <template #input>
                                <van-switch 
                                :active-value="1"
                                :inactive-value="0"
                                v-model="houseData.isNeedIdCard" 
                                :size="getVw(20)" 
                                :disabled="disabled" />
                            </template>
                            <template #label>
                                <p>上传相关证件</p>
                                <p class="color-999 ft-12">开启后需要上传客户相关资质证件</p>
                            </template>
                        </van-field>
                        <van-field name="switch" label="特殊提醒" :label-width="getVw(250)" input-align="right" :disabled="disabled">
                            <template #input>
                                <van-switch 
                                :active-value="1"
                                :inactive-value="0"
                                v-model="houseData.isAlert" 
                                :size="getVw(20)" 
                                :disabled="disabled" />
                            </template>
                            <template #label>
                                <p>开启特殊提醒</p>
                                <p class="color-999 ft-12">开启楼盘自定义特殊提醒</p>
                            </template>
                        </van-field>
                        <van-field 
                        v-model="houseData.specialTip"
                        label="提醒内容" 
                        label-width="5em" 
                        placeholder="请输入特殊提醒内容" 
                        required
                        clearable
                        :disabled="disabled"
                        v-if="houseData.isAlert"></van-field>
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item_title">基本信息</div>
                    <div class="form-item__content">
                        <van-field 
                        v-model="houseData.name"
                        label="楼盘名称" 
                        label-width="5em" 
                        placeholder="请输入楼盘名称" 
                        required
                        :disabled="disabled"></van-field>
                        <van-field
                        readonly
                        is-link
                        name="area"
                        required
                        label-width="5em" 
                        :value="cityName"
                        label="地区选择"
                        :disabled="disabled"
                        placeholder="点击选择省市区"
                        @click="openCityPicker"
                        />
                        <van-field 
                        v-model="houseData.averagePrice"
                        label="楼盘均价" 
                        label-width="5em" 
                        placeholder="请输入楼盘均价(元/㎡)" 
                        :disabled="disabled" type="number">
                        <template #extra>
                            <span class="color-999">(元/㎡)</span>
                        </template>
                        </van-field>   
                        <van-field 
                        v-model="houseData.packagePrice"
                        label="楼盘总价" 
                        label-width="5em" 
                        placeholder="请输入楼盘套价" 
                        :disabled="disabled" type="number">
                        <template #extra>
                            <span class="color-999">(万/套)</span>
                        </template>
                        </van-field>
                        <van-field
                        readonly
                        clickable
                        required
                        label-width="5em" 
                        :value="saleStatusName"
                        label="销售状态"
                        :disabled="disabled"
                        placeholder="点击选择销售状态"
                        is-link
                        @click="openSaleStatusPicker"
                        />
                        <van-cell class="field-cover-cell">
                            <template>
                                <div class="field-cover">
                                    <div class="field-item">
                                         <van-field 
                                          input-align="center"
                                          placeholder="0"
                                          label="报备保护期"
                                        v-model="houseData.reportProtectDays"
                                        :disabled="disabled" type="number">
                                        <template #extra>
                                            <span class="color-999">天</span>
                                        </template>
                                        </van-field>
                                    </div>
                                    <div class="field-item">
                                         <van-field 
                                          input-align="center"
                                          placeholder="0"
                                        v-model="houseData.reportProtect"
                                        :disabled="disabled" type="number">
                                        <template #extra>
                                            <span class="color-999">小时</span>
                                        </template>
                                        </van-field>
                                    </div>
                                </div>
                            </template>
                        </van-cell>      
                        <van-cell  class="field-cover-cell">
                            <template>
                                <div class="field-cover">
                                    <div class="field-item">
                                        <van-field 
                                        input-align="center"
                                        placeholder="0"
                                        label="带看保护期"
                                        v-model="houseData.takeLookProtectDays"
                                        :disabled="disabled" type="number">
                                        <template #extra>
                                            <span class="color-999">天</span>
                                        </template>
                                        </van-field>
                                    </div>
                                    <div class="field-item">
                                        <van-field 
                                        input-align="center"
                                        placeholder="0"
                                        v-model="houseData.takeLookProtect"
                                        :disabled="disabled" type="number">
                                        <template #extra>
                                            <span class="color-999">小时</span>
                                        </template>
                                        </van-field>
                                    </div>
                                </div>
                            </template>
                        </van-cell>
                        <van-field 
                        label-width="6em"
                        class="upload-field"
                        :disabled="disabled">
                        <template #label>
                            <p>缩略图</p>
                            <p class="color-999 ft-12">(宽624*高400)</p>
                        </template>
                        <template #input>
                            <van-uploader
                            accept="image/*"
                            :after-read="afterRead"
                            :disabled="disabled"
                            >
                                <div class="upload-thumb-cover">
                                    <img :src="houseData.buildingDiagram|$imageUrl" alt="" v-if="houseData.buildingDiagram">
                                    <div class="upload-placeholder" v-else><van-icon name="photograph" /></div>
                                </div>
                            </van-uploader>
                        </template>
                        </van-field> 
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item_title">佣金详情</div>
                    <div class="form-item__content">
                        <van-field 
                        v-model="houseData.commission.commissionPoint"
                        label="佣金点数" 
                        label-width="5em" 
                        placeholder="请输入佣金点数" 
                        :disabled="disabled"></van-field>      
                        <van-field 
                        label="佣金政策"
                        label-width="5em" 
                        clearable 
                        v-model="houseData.commission.commissionPolicy" 
                        autosize 
                        placeholder="请输入佣金政策" 
                        type="textarea" 
                        rows="3" 
                        :disabled="disabled"></van-field> 
                        <van-field 
                        v-model="houseData.commission.commissionRule"
                        autosize
                        label="结佣规则" 
                        label-width="5em" 
                        clearable 
                        placeholder="请输入结佣规则" 
                        type="textarea" 
                        rows="3" 
                        :disabled="disabled"
                        ></van-field>    
                        <van-field label="奖励政策" 
                        v-model="houseData.commission.incentivePolicy"
                        label-width="5em" 
                        clearable 
                        placeholder="请输入奖励政策" 
                        type="textarea" 
                        rows="3" 
                        :disabled="disabled"></van-field>
                        <van-field label="开发商说明" 
                        v-model="houseData.commission.developerDescription"
                        label-width="5em" 
                        clearable 
                        placeholder="请输入开发商说明" 
                        type="textarea" 
                        rows="3" 
                        :disabled="disabled"></van-field>
                    </div>
                </div>
            </div>
        </div>
        <!-- 地区选择弹窗 -->
        <van-popup v-model="showArea" position="bottom">
            <van-picker 
            show-toolbar 
            ref="cityPicker"
            title="楼盘地区选择" 
            :columns="cityColumns" 
            @cancel="showArea=false"
            @confirm="selectedCity"/>
        </van-popup> 
        <!-- 销售状态选择弹窗 -->
        <van-popup v-model="saleStatusPickerShow" position="bottom">
            <van-picker 
            show-toolbar 
            title="选择销售状态" 
            :columns="saleStatusColumns" 
            @cancel="saleStatusPickerShow=false"
            @confirm="selectedSaleStatus"
            :default-index="saleStatusDefaultIndex"/>
        </van-popup>
   </div>
</template>

<script>
import backMixin from "@/mixins/back";
import {pxToVw,cloneDeep,isEqual,isObjectNone,findIndex,isArray} from "@/utils";
import imageUploadMixin from "@/mixins/imageUpload"
import api from "@/api"
export default {

    mixins:[backMixin,imageUploadMixin],

    data(){

        return{

            houseData:{
                commission:{}
            },
            
            disabled:false,

            //旧的报备开关数据

            oldData:null,

            //是否显示地区选择

            showArea:false,

            cityColumns:[],

            inited:false,

            saleStatusPickerShow:false,

            saleStatusDefaultIndex:0,

            saleStatusObj:{

                "SELLING":'在售',

                "PENDING":'待售',

                "SELL_OUT":'售罄',

                "OFFER_BUY":'认筹',

                "SELL_SOON":"即将开盘",

                "STOP_SELL":'停售'

            },

            saleStatusColumns:[
                { text: "在售", value: "SELLING" },
                { text: "待售", value: "PENDING" },
                { text: "售罄", value: "SELL_OUT" },
                { text: "认筹", value: "OFFER_BUY" },
                { text: "即将开盘", value: "SELL_SOON" },
                { text: "停售", value: "STOP_SELL" },
            ],

            houseRecordTypeObj:{
                isFullName:'ALL_NAME',
                isHideTel:'PRIVACY',
                isNeedIdCard:'CERTIFICATE',
                isNotFullName:'SURNAME'

            }

        }

    },

    computed:{

        houseTitle(){

            return this.$route.query.name ? decodeURI(this.$route.query.name) : '楼盘详情'

        },

        cityName(){

            return this.houseData?.buildingRegionCn || '';

        },

        saleStatusName(){
            
            return this.houseData?.sellingStatus ? this.saleStatusObj[this.houseData?.sellingStatus] : '';

        },

        rightText(){

            return this.$route.params.id == 'add' ? '新增' : '提交'

        }

    },

    methods:{

        getVw(px){

            return pxToVw(px) + 'vw';

        },

        //请求楼盘数据

        getHouseData(){

            this.$toast.loading({

                message:'正在加载...',

                duration:0,

                overlay:true

            });

            api.getHouseData({

                options:{

                    buildingId:this.$route.params?.id

                },

                success:(res)=>{

                    if(res.type == 200){

                        this.houseData= cloneDeep(res.data);

                        this.oldData = cloneDeep(res.data);

                        //默认选中销售状态

                        let index = findIndex(this.saleStatusColumns,(el)=>{

                            return el.value == this.houseData?.sellingStatus;

                        });

                        this.saleStatusDefaultIndex = index == -1 ? 0 : index; 

                        this.disabled=false;
                        
                    }else{

                        this.$toast(res.msg||'获取楼盘详情数据失败，请稍后重试');

                        this.disabled=true;

                    }
                    
                },

                error:(err)=>{

                    console.log(err);

                    this.$toast('获取楼盘详情数据失败，加载异常，请稍后重试，'+err);

                    this.disabled=true;

                },

                complete: ()=>{

                    this.$toast.clear();
                    
                }

            })

        },

        //提交前检查方法
        checkBuildingRepeat(){
          return  new Promise((res,rej)=>{
                this.$toast.loading({

                    message:'校验中...',

                    duration:0,

                    forbidClick:true

                });
            api.checkBuildingRepeat({
                options:{
                    buildingId:this.houseData.id,
                    buildingName:this.houseData.name
                },
                success:(_res)=>{
                    if(_res.type == 200){
                        if(_res.data){
                            this.$toast({
                                message:'已有相同名称楼盘，请勿重复创建',
                                duration:5000
                            });   
                            res(true);
                        }else{
                            res(false);
                        }
                    }else{
                        res(true);
                        this.$toast({
                            message:"提交失败，数据异常，"+res?.msg || err,
                            duration:5000
                        });
                    }
                },
                error:(err)=>{
                    console.log(err)
                    this.$toast({
                        message:"提交失败，数据异常，"+err?.msg || err,
                        duration:5000
                    });
                    res(false);
                },
                complete:()=>{
                    this.$toast.clear();
                }
          })
          })
        },

        //提交楼盘数据

        async submitHouseData(){

            let isAdd = this.$route.params.id == 'add'

            //禁用状态下不允许提交

            if(this.disabled){

                this.$toast('没有需要提交的数据');

                return;

            }

            //数据检查

            if(!this.houseData.name){

                this.$notify({ type: 'warning', message: '请填写楼盘名称'});

                return;

            }

            if(!this.houseData.buildingRegion){

                this.$notify({ type: 'warning', message: '请选择楼盘地区'});

                return;

            }

            if(!this.houseData.sellingStatus){

                this.$notify({ type: 'warning', message: '请选择销售状态'});

                return;

            }            

            if(this.houseData.isAlert){

                if(!this.houseData.specialTip){

                    this.$notify({ type: 'warning', message: '请填写特殊提醒内容'});

                    return;

                }


            }

            
            //是否进行特殊提示

            let isSellReport = false;

            let formData = cloneDeep(this.houseData);
            //特殊处理的参数
            let typeList = ['isFullName','isHideTel','isNeedIdCard','isNotFullName']

            formData.buildingId = formData.id;

            //转化为提交数据
            formData.reportContent = [];

            typeList.forEach(el=>{
                if(formData[el]){
                    formData.reportContent.push(this.houseRecordTypeObj[el]);
                }
            });
            

            if(!isAdd){

                /**
                 * 
                 * 当用户关闭楼盘报备状态时，此次提交进行提醒
                 * 
                 */

                if(!this.houseData.isSellReport && this.houseData.isSellReport !== this.oldData.isSellReport){

                    isSellReport = true;

                }
            }else{
                //防止报错
                formData.buildingTheme = [];
            }
            
            //首先校验一下姓名

            let check = await this.checkBuildingRepeat();
            
            //重名限制提交
            if(check) return;
            
            
            this.$dialog.confirm({

                title:isAdd ? '新增楼盘' : isSellReport ? '关闭楼盘报备' :'提交修改',

                message:`${isAdd ? '确认要新增楼盘吗？' : isSellReport ? '您关闭了此楼盘的分销报备，这将导致用户无法在报备系统里对此楼盘进行报备，如是误操作将导致严重后果，确认要进行提交吗？' : '确定要对楼盘数据进行更改吗？'}`,

                confirmButtonText:isAdd ? '确认新增' : isSellReport ? "确认关闭报备" : "确定提交",

                confirmButtonColor:"#00a0e4"


            }).then(()=>{
                

                this.$toast.loading({

                    message:'正在提交',

                    duration:0,

                    forbidClick:true

                });

                api[isAdd ? 'createHouseData' : 'updateHouseData']({

                    options:formData,


                    success:(res)=>{

                        if(res.type == 200){

                            this.$dialog.alert({

                                title:'提交成功',

                                message:`${this.houseData.name} ${isAdd ? '楼盘新增成功' : '数据提交成功'}`,

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{

                                this.$nextTick(()=>{

                                    this.$router.back();

                                })

                            })

                        }else{

                            this.$toast.fail(res.msg || '提交失败，请稍后重试');

                        }

                    },

                    error:(err)=>{

                         this.$toast({

                             message:"提交失败，数据异常，"+err,

                             duration:5000

                         });

                    },

                    complete: ()=>{

                        this.$toast.clear();
                        
                    }

                })
                

            }).catch(()=>{})


        },

        //选择楼盘城市

        selectedCity(val,index){
            //根据index找到对应的数据
            let cityId = this.cityColumns[index[0]]?.children[index[1]]?.value;
            this.$set(this.houseData,'buildingRegion',cityId);
            this.$set(this.houseData,'buildingRegionCn',val.join('-'));
            this.showArea = false;

        },

        //将城市数据转化为对应的picker数据
        
        setCityColumns(city){

            console.log(city)

            let _city = cloneDeep(city);

            _city.forEach((el)=>{

                el.value = el.value;

                el.children && el.children.forEach((_el)=>{

                    _el.value = _el.value;

                    this.$delete(_el,'children');

                });

                if(el.children){

                    el.children = el.children.filter(_el=>{

                        return _el.text != '不限'

                    })
                    
                }

            });

            return _city;

        },

        //每次打开都找到对应的数据，并选中

        openCityPicker(){
            if(this.disabled) return;
            this.showArea = true;

            if(!this.inited){

                let indexs = this.getCityIndex();

                if(isArray(indexs) && indexs.length > 0){

                    this.$nextTick(()=>{

                        this.$refs.cityPicker.setColumnIndex(0,indexs[0],indexs[1]);

                        this.inited=true;

                    })

                }

            }
        
         

        },

        //获取当前选中的index列表

        getCityIndex(){

            //首选检查是否存在

            if(!this.houseData?.buildingRegion) return;

            let indexs = [];

            for(let i =0;i<this.cityColumns.length;i++){

                if(this.cityColumns[i].children){

                    for(let j=0;j<this.cityColumns[i].children.length;j++){

                        if(this.cityColumns[i].children[j].value == this.houseData?.buildingRegion){
                            
                            indexs.push(i,j);

                            break;
                            
                        }

                    }

                }else{

                    continue;

                }

                if(indexs.length != 0){

                    break;

                }


            }

            return indexs;
   
        },

        //打开销售状态选择

        openSaleStatusPicker(){
            if(this.disabled) return;
            this.saleStatusPickerShow = true;

        },

        //选择销售状态

        selectedSaleStatus(val){

            this.$set(this.houseData,'sellingStatus',val.value);

            this.saleStatusPickerShow=false;

        },

         //上传图片

        afterRead(file) {

            const _check = this.beforeThumbUpload(file.file);

            if(!_check) return;

            const formData = new FormData();         
            
            formData.append('file',file.file);

            api.upload({

                options:formData,

                success:(res)=>{

                    if(res.type == 200){

                        this.$set(this.houseData,'buildingDiagram',res.data?.url);

                    }else{

                        this.$notify({ type: 'danger', message: res.msg || '图片上传失败，请稍后重试' });

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$notify({ type: 'danger', message:'图片上传失败，请检查网络，'+err});

                }

            })
        },












        //初始化方法

        init(){

            if(this.$route.params.id != 'add'){

                this.getHouseData();

            }

            this.cityColumns = this.setCityColumns(this.$store.state.userInfo?.cityList || []);

        }

    },


    mounted(){

        this.$fky_callback(()=>{

            this.init();

        },200)

    }

}
</script>

<style scoped lang="less">
.detail-set-wrapper{

    &,.cover{

        height:100%

    }

    .field-cover{
        display: flex;
        align-items: center;
        .field-item{
            flex:1;
            &:first-child{
                flex:1.5
            }
        }
    }

    .bd{

        flex:1;

        height:0;

        overflow: hidden;

        overflow-y:auto;

        background: #f7f8fa;

        .form-item{

            margin-top:10px;

            margin-bottom:16px;

        }

        .thumb-cover{

            width: 100px;

            height:80px;

            img{

                width: 100%;

                height:100%;

            }

        }

        .form-item_title{

            padding: 6px 16px 16px;

            font-size:14px;

            color:rgba(69, 90, 100, 0.6);

        }

    }

}
</style>
